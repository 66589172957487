import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer";
import BelowBanner from "../components/contactus/BelowBanner";
import Banner from "../components/layout/Banner";
import BgImage from "../assets/images/contactus/Banner.png";
import { Helmet } from "react-helmet";

const ContactUs=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Contact Us | Connect for Exceptional Healthcare Support</title>


        <meta name="description" content="Reach out to our dedicated team for outstanding healthcare services in Huntsville. Contact us today to discuss your healthcare needs and experience personalized care at its best." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us | Connect for Exceptional Healthcare Support" />
        <meta property="og:description" content="Reach out to our dedicated team for outstanding healthcare services in Huntsville. Contact us today to discuss your healthcare needs and experience personalized care at its best." />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Reach out to our dedicated team for outstanding healthcare services in Huntsville. Contact us today to discuss your healthcare needs and experience personalized care at its best." />
        <meta name="twitter:title" content="Contact Us | Connect for Exceptional Healthcare Support" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />
        </Helmet>
        <Header/>
        <Banner
        BgImage={BgImage}
        Title1="Contact Us"
        Title2="Empowering Care,"
        br={<br className="d-block d-md-none d-xl-block"/>}
        Title2_2="Healing Lives:"
        Title3="Your Journey Begins Here." 
        />
        <BelowBanner/>
        <Footer/>
        </>
        
    )
};

export default ContactUs;