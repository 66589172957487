import React from "react"
import { Link } from "react-router-dom"


const TakeActionToday = () =>{
    return (
    <>
    <section className="container-hsb">
    <div className="row text-center mb-4 mb-xl-0">
    <div className="col-12 text-md-center">
    <h3 className="font-pri-hsb font-25 fw-600 text-capitalize">
        Discover a world of medical knowledge
        </h3>
        <h2 className="font-pri-hsb font-35 text-pri-hsb fw-800 text-uppercase">
            Take action today!
        </h2>
        <a href="tel:+12564899741" target="_blank">
        <button className=" mt-4 lh-30 px-5 border-0 bg-sec text-white rounded-3 fw-600 font-pri-hsb">
            Book Now
        </button>
        </a>
        </div>
            </div>
    </section>
    </>
    )
}

export default TakeActionToday;