import React, { Component } from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import SlickSliderOne from "../components/SlickSliderOne";
import Img1 from "../assets/images/testimonials/Img1.png";
import Img2 from "../assets/images/testimonials/Img2.png";
import Img3 from "../assets/images/testimonials/Img3.png";
import Img4 from "../assets/images/testimonials/Img4.png";
import Icon from "../assets/images/testimonials/Icon.png";
import Google from "../assets/images/testimonials/Google.png";
import GoogleIcon from "../assets/images/testimonials/GoogleIcon.png";
import { StarFill, StarHalf } from 'react-bootstrap-icons';

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 7000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const stars = (rating) => {
      let stars = [];
      for (let i = 1; i < rating; i++) {
          stars.push(<StarFill key={i} className="text-warning" />)
      }
      if (rating % 1 !== 0) {
          stars.push(<StarHalf key={rating} className="text-warning" />)
      }else{
          stars.push(<StarFill key={rating} className="text-warning" />)
      }
      return stars;
  }
    return (
      <>
      <section className="container-fluid pb-5 mb-lg-4">
      <div className='fs-ss-36 text-raleway fw-800 text-ss-primary'>
        <h3 className="font-35 font-pri-hsb text-center">
            Patient <span className="fw-800 text-pri-hsb"> Testimonials </span>
            </h3>
            <hr className="d-none d-md-block hr2"/>
            <hr className="d-block d-md-none w-25 hr2"/>
            </div>
        <div className="row justify-content-center gy-4 gx-0">
          <div className="col-10 col-lg-3 align-self-center">
          <div className='d-block d-lg-flex align-items-center text-center'>
        <div className='me-2 align-self-start py-3 py-lg-0'>
        <img src={Icon} alt="" className=''/>
        </div>
     <div className='fs-ss-20 lh-sm' style={{color:"black"}}>
      <div className="fw-700 text-uppercase"> 
      <a href="https://maps.app.goo.gl/3YQP3WEpu7JSByFQ7" target="_blank" className="text-decoration-none text-black"> My Family MD </a> 
      </div>
      <div className="fw-600 py-2" style={{color:"#f9bd0c"}}> 4.7 {stars(4.5)} </div> 
     <div className="fs-ss-16 fw-400"> Based on 58 reviews </div>
     <img className="" src={Google} alt="Google" style={{width:"140px"}}/>
     <div className="fs-ss-14 fw-600 pt-3">
      <a href="https://g.page/r/CSTQGalk-DBcEBM/review" target="_blank" className="text-decoration-none text-white rounded-5 py-2 px-4 " style={{backgroundColor:"#0a6cff"}}>
        review us on <img className="bg-white rounded-5 p-1" src={GoogleIcon} alt="GoogleIcon" style={{width:"20px"}} />
      </a>
     </div>
     </div>

      </div>
          </div>
        <div className="col-8">
        <Slider {...settings}>
        <SlickSliderOne
        ImgReviews={Img1}
        Name="Andrew Prete"
        Rating="5"
        Para="My wife and I have been seeing Dr. Saeed since we moved here from Chicago 10+ years. The Staff is professional and kind. Dr Saeed is the same and they all care about you. He is always staying current with technology and because he is affiliated with Huntsville Hospital he is able to see all the results from any tests that have been done. You will not be disappointed. "
        />
        <SlickSliderOne
        ImgReviews={Img2}
        Name="Jordan D"
        Rating="5"
        Para="Dr. Saeed and staff were incredibly helpful and kind when I scheduled an appointment with them this past February. They were prompt with scheduling my appointment, and the front office staff members were very friendly when I arrived. Dr. Saeed was compassionate and knowledgeable and was able to alleviate my symptoms. I had a great experience and would recommend this practice to friends and family."
        />
        <SlickSliderOne
        ImgReviews={Img3}
        Name="Nikki Bartlett (Nikki)"
        Rating="5"
        Para="Dr. Saeed is a wonderful doctor. His entire staff truly care for the patients and work hard every day. This clinic will get to know you and your family. They listen to your concerns, work with your insurance to get approvals, give samples until able to get medications approved, and even find work arounds to get medications/antibiotics for free or affordable costs with and without insurance. I highly recommend all my family and friends to this clinic."
        />
        <SlickSliderOne
        ImgReviews={Img4}
        Name="Lanny Moore"
        Rating="5"
        Para="I always have the best care when it comes to Dr. Saeed and his staff I love the way Dr.Saeed and his assistants keep me healthy... thank you for all you guys do.
        "
        />
        </Slider>
      </div>
        </div>
        <div className="text-center">
        <a href="https://www.google.com/maps/place/Iqbal+Saeed+-+My+Family+MD+LLC/@34.6994209,-86.6028594,15z/data=!4m8!3m7!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!9m1!1b1!16s%2Fg%2F1tfbjg5q?entry=ttu" target="_blank">
            <button className="mt-4 lh-30 px-4 border-0 bg-sec text-white rounded-3 fw-600 font-pri-hsb">
                View All
            </button>
            </a>
            </div>
      </section>
      </>
    );
  }
}