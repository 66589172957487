import React from "react"
import { Link } from "react-router-dom"


 
const TwoImgsTwoTexts = (props) =>{
    return (
    <>
            {/* Part 1 */}
    <section id={props.IDDoctor} className="container-fluid">
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className={`col-12 col-md-5 section-img-1 ${props.ProvidersCls1}`} style={{backgroundImage:`url(${props.Img1})`}}>
            <img className='img-fluid invisible' alt={props.Alt1} src={props.Img1} />    
            </div>
            {/* First Heading */}
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5 my-xl-5"> 
            <div className=" px-xl-5  px-3">
            {/* subtitle */}
            <h3 className="font-25 font-pri-hsb"> {props.Title1_1} <span className="fw-600 text-pri-hsb"> {props.Title1_2} </span> </h3>
            <h3 className="font-35 font-pri-hsb"> {props.TitleH3_1} <span className="fw-800 text-pri-hsb"> {props.TitleH3_2} </span> </h3>
            {/* Title */}
            <h2 className="font-pri-hsb font-35 fw-700 text-pri-hsb text-uppercase"> {props.SubTitle1} </h2>
            {/* hr line  */}
            {props.hr1} 
            {/* para */}
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para1} {props.br1_1}{props.Para1_1_2} {props.br1_2}{props.Para1_1_3} 
            </p>
            </div>

            {/* Second Heading */}
            <div className="px-xl-5 px-3">
            {/* title */}
            <h2 className="font-35 font-pri-hsb"> {props.Title1_3} <span className="fw-800 text-pri-hsb"> {props.Title1_4} </span> </h2>
            <h3 className="font-35 font-pri-hsb"> {props.TitleH3_3} <span className="fw-800 text-pri-hsb"> {props.TitleH3_4} </span> </h3>
            {/* hr line  */}
            {props.hr1_2}
            {/* para */}
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para1_2} {props.br1_3}
                {props.Para1_3}
            </p>
            </div>
            </div>
        </div>
        </section>
        {/* Part 2 */}
        <section className= {props.Display}>
        <div className="row" style={{backgroundColor: "#f6f6f6"}}>
        <div className={`d-block d-md-none col-12 col-md-5 section-img-2 ${props.ProvidersCls2}`} style={{backgroundImage:`url(${props.Img2})`}}>
            <img className='img-fluid invisible' alt={props.Alt2} src={props.Img2} />    
            </div>
            <div className="align-self-center col-12 col-md-7 py-5 ps-xl-5 my-xl-5" > 
            <div className=" px-xl-5 px-3">
            {/* subtitle */}
            <h3 className="font-25 font-pri-hsb"> {props.Title2_1} <span className="fw-700 text-pri-hsb"> {props.Title2_2} </span> </h3>
            {/* Title */}
            <h2 className="font-pri-hsb font-35 fw-700 text-pri-hsb text-uppercase"> {props.SubTitle2} </h2>
            {props.hr2}
            {/* para */}
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para2}{props.br2_1}{props.Para2_2_2}{props.br2_2}{props.Para2_2_3} 
                {props.ParaWithBulletPoints}
            </p>
            </div>

            <div className=" px-xl-5  px-3">
            {/* title */}
            <h2 className="font-35 font-pri-hsb"> {props.Title2_3} <span className="fw-800 text-pri-hsb"> {props.Title2_4} </span> </h2>
            {props.hr2_2}
            {/* para */}
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para2_2}
            </p>
            </div>

            <div className=" px-xl-5  px-3">
            {/* title */}
            <h2 className="font-35 font-pri-hsb"> {props.Title2_5} <span className="fw-800 text-pri-hsb"> {props.Title2_6} </span> </h2>
            {props.hr2_3}
            {/* para */}
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para2_3}
            </p>
            </div>

            <div className=" px-xl-5  px-3">
            {/* title */}
            <h2 className="font-35 font-pri-hsb"> {props.Title2_7} <span className="fw-800 text-pri-hsb"> {props.Title2_8} </span> </h2>
            {props.hr2_4}
            {/* para */}
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para2_4}
            </p>
            </div>

            </div>
            <div className={`d-none d-md-block col-12 col-md-5 section-img-2 ${props.ProvidersCls2}`} style={{backgroundImage:`url(${props.Img2})`}}>
            <img className='img-fluid invisible' alt={props.Alt2} src={props.Img2} />    
            </div>
        </div>
    </section>
    </>
    )
}

export default TwoImgsTwoTexts;