import React from "react"

const ThreeHeadings = (props) =>{
    return (
    <>
    <section className="container-fluid px-md-5 ">
        <div className="row align-items-center justify-content-start">
            <div className="col-6 col-sm-2">
            <img className='w-75' alt='Icon' src={props.Img}/>    
            </div>
            <div className="col-12 align-self-center col-sm-10 py-3"> 
            <h3 className="font-35 font-pri-hsb">
                {props.Title1_1} <span className="fw-800 text-pri-hsb"> {props.Title1_2} </span>
            </h3>
            <p className="font-17 font-sec2-hsb fw-500">
                {props.Para1}
            </p>
            </div>
        </div>

    </section>
    </>
    )
}

export default ThreeHeadings;