export const PrimaryCareSchema = () => {

  let data = {
    "@context": "https://schema.org", 
    "@type": "Service", 
    "serviceType": "Primary Care", 
    "provider": { 
      "@type": "Organization", 
      "name": "My Family MD", 
      "url": "https://myfamilymd.org/", 
      "contactPoint": { 
        "@type": "ContactPoint", 
        "telephone": "(256)-489-9741", 
        "contactType": "customer service" 
      } 
    }, 
    "areaServed": { 
      "@type": "City", 
      "name": "Huntsville" 
    }, 
    "description": "Comprehensive primary care services for individuals and families, focusing on preventive healthcare, diagnosis, and treatment.", 
    "url": "https://myfamilymd.org/primary-care-clinic-huntsville", 
    "image": "https://myfamilymd.org/og.png"      
  } 

  return JSON.stringify(data);
};