import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import ServicesPara from "../components/services/ServicesPara";
import CTA from "../components/services/CTA";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import {TelemedicineSchema} from '../components/schemas/TelemedicineSchema';
import OurInsurance from "../components/layout/OurInsurance";
import TakeActionToday from "../components/layout/TakeActionToday";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/ourservices/telemedicine/Img1.png"
import Img2 from "../assets/images/ourservices/telemedicine/Img2.png"
import BgImage from "../assets/images/ourservices/telemedicine/Banner.png";
import Banner from "../components/layout/Banner";
import { Helmet } from "react-helmet";



const Telemedicine=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Convenient Telehealth Services in Huntsville, AL | My Family MD</title> 
        <meta name="description" content="Access quality healthcare conveniently with telehealth services in Huntsville, AL. Online consultations, diagnoses, and prescriptions. Schedule an appointment now!" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Convenient Telehealth Services in Huntsville, AL | My Family MD" />
        <meta property="og:description" content="Access quality healthcare conveniently with telehealth services in Huntsville, AL. Online consultations, diagnoses, and prescriptions. Schedule an appointment now!" />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Access quality healthcare conveniently with telehealth services in Huntsville, AL. Online consultations, diagnoses, and prescriptions. Schedule an appointment now!" />
        <meta name="twitter:title" content="Convenient Telehealth Services in Huntsville, AL | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />

        <script className='structured-data-list' type="application/ld+json">{TelemedicineSchema()}</script>
 

        </Helmet>
        <Header/>
        <Banner
        Alt="Telehealth Doctor In Huntsville"
        BgImage={BgImage}
        Title1="The Top"
        Title2="Telemedicine Services"
        br={<br className="d-none d-md-block"/>}
        Title2_2="In Huntsville, AL"
        Title3="Bringing healthcare" 
        Title3_3="To Your fingertips"
        />
        <ServicesPara
        Title1_1="Convenient Access to Quality Healthcare:"
        Title1_2="Telehealth Doctor In Huntsville, Al"
        Para1_1="In today's fast-paced world, accessing quality healthcare has become more convenient and accessible than ever with the availability of telehealth services. Telehealth allows patients to connect with healthcare providers remotely, utilizing technology to bridge the gap between patients and doctors. By leveraging video calls, online portals, and other communication tools, patients can have real-time discussions with healthcare professionals, receive diagnoses, explore treatment options, and even obtain prescriptions, all from the comfort of their own homes."
        Para1_2="One of the significant advantages of telehealth services huntsville is the ease of access to licensed doctors and therapists from anywhere, allowing patients to consult with healthcare professionals for a wide range of non-emergency medical conditions. Whether it's seeking medical advice, managing chronic conditions, or receiving follow-up care, telehealth provides a convenient and efficient way to address healthcare needs.  "
        />
        <CTA/>

        <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-md-5 section-img-1" style={{backgroundImage:`url(${Img1})`}}>
            <img className='img-fluid invisible' alt="Online Doctor Consultation Huntsville" src={Img1} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> Simplifying Healthcare <span className="fw-800 text-pri-hsb">  with Telehealth: </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            From simple issues like rashes and colds to ongoing care for chronic conditions, teleconference with a doctor offers a time-saving alternative to traditional in-person visits, reducing the need for travel and long waiting times. Discover the convenience and accessibility of telehealth services by connecting with a trusted online doctor near you in Huntsville and prioritize your health without compromising your busy schedule. Embrace the power of online doctor consultation to receive quality healthcare conveniently and effectively.
            </p>
            </div>
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> The Benefits of <span className="fw-800 text-pri-hsb">  Telemedicine Services: </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            The ever-evolving landscape of healthcare, telemedicine has emerged as a game-changer, offering a plethora of benefits that make it a preferred choice for many individuals. One of the foremost advantages of telemedicine is its unmatched convenience. With telemedicine, patients can connect with healthcare providers from the comfort of their own homes, eliminating the need for lengthy commutes, waiting room delays, and time-consuming visits to a doctor's clinic. This level of convenience is particularly valuable for those with busy schedules or limited mobility. <br/><br/>
            With its ability to bridge the gap between patients and healthcare providers, telemedicine opens up new possibilities for accessing quality medical care, promoting proactive health management, and enhancing overall patient outcomes. Telemedicine, also known as telehealth, offers many advantages, including:
            </p>
            </div>
            </div>
        </div>
        <div className="row" style={{backgroundColor: "#f6f6f6"}}>
        <div className="d-block d-md-none col-12 col-md-5 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="Benefits of Telemedicine" src={Img2} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> No Transportation <span className="fw-800 text-pri-hsb"> Costs </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Due to online consultation, a good sum of money on gas, parking, and public transportation can be saved. And what’s even better is that we don’t have to waste time traveling or risk getting stuck into a traffic jam, making us late for the appointment, or worse, late getting back to work.
            </p>
            </div>
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> No Taking <span className="fw-800 text-pri-hsb"> Time Off Of Work </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            During online treatments, there is no need to take time off for a doctor’s visit. The visit can be scheduled during a break or before or after work.
            </p>
            </div>
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> No Leaving <span className="fw-800 text-pri-hsb"> Kids Behind </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Arranging alternative care before going to the hospital for your kids or older people of the house is such a hassle and, at most times, expensive. While bringing them along will be equally stressful or impossible. Fortunately, online doctor consultations solves this predicament by allowing you to see your doctor from the comfort of your home.
            </p>
            </div>
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> The <span className="fw-800 text-pri-hsb"> Takeaway </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Telemedicine doesn’t mean that you’ll never need to revisit a hospital. But the virtual care does make care more accessible and affordable by delivering better outcomes and lowering costs for everyone. Contact My family MD for online consultancy and treatments and keep enjoying a healthy life. Your well-ness is our priority!
            </p>
            </div>
            </div>
            <div className="d-none d-md-block col-12 col-md-5 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="Benefits of Telemedicine" src={Img2} />    
            </div>
        </div>
        </section>

        <div className="pt-4">
        </div>
        <MiddleBannerMap/>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default Telemedicine;