import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import CTA from "../components/providers/CTA2";
import TwoImgsTwoTexts from "../components/aboutus/TwoImgsTwoTexts";
import TwoImgsTwoTexts2 from "../components/providers/TwoImgsTwoTexts2";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import OurInsurance from "../components/layout/OurInsurance";
import TakeActionToday from "../components/layout/TakeActionToday";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/providers/2.webp"
import Img2 from "../assets/images/providers/1.webp"
import Img3 from "../assets/images/providers/3.webp"
import Img4 from "../assets/images/providers/4.webp"
import Img5 from "../assets/images/providers/5.webp"
import Img6 from "../assets/images/providers/6.webp"
import Banner from "../components/layout/Banner";
import DoctorBanner from "../components/layout/DoctorBanner";
import BgImage from "../assets/images/providers/banner1.webp";
import Dr from "../assets/images/home/Dr.webp"
import { Helmet } from "react-helmet";

const Providers=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Meet Our Expert Healthcare Providers | My Family MD</title>

        
        <meta name="description" content="Meet Our Expert Healthcare providers Team at My Family MD - Dedicated professionals committed to delivering exceptional care." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Meet Our Expert Healthcare Providers | My Family MD" />
        <meta property="og:description" content="Meet Our Expert Healthcare providers Team at My Family MD - Dedicated professionals committed to delivering exceptional care." />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Meet Our Expert Healthcare providers Team at My Family MD - Dedicated professionals committed to delivering exceptional care." />
        <meta name="twitter:title" content="Meet Our Expert Healthcare Providers | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />
        </Helmet>
        <Header/>
        <Banner
        BgImage={BgImage}
        Title1="Providers"
        Title2="We are with you"
        Title3="Every step of the way" 
        />
                <DoctorBanner
            IDDoctor="dr-iqbal-saeed"
            SubTitle="Dr. Iqbal Saeed" 
            Title1="Medical" 
            Title2="Director"
            Para="Dr. Iqbal Saeed is a board certified internal medicine physician. He has been practicing medicine in Alabama since 1996. He completed his residency training from New York City. Dr. Saeed is a caring and compassionate physician who listens to the concerns of his patients and their needs. Come see if our practice may be the right fit for your journey to better health."
            Img={Dr}
            />
        <CTA/>
        <TwoImgsTwoTexts
        ProvidersCls1="section-img-1-providers"
        ProvidersCls2="section-img-2-providers"
        IDDoctor="emily-klae-carnes"
        Display="container-fluid"
        SubTitle1="Kimberly Ejide – MBA"
        SubTitle2="Michelle Gasque"
        Alt1="Emily Klae Carnes"
        Alt2="Kimberly Ejide"
        Img1={Img1}
        Img2={Img2}
        Title1_1="Office"
        Title1_2="Manager"
        hr1={<hr className="hr w-25" />}
        Para1="Kimberly’s role is to oversee the office operations and ensure patient satisfaction. Over the past 13 years working with Dr. Saeed, Kimberly has shown tremendous passion for our patients and routinely meets and exceeds their needs. Her commitment to create a positive environment has made her invaluable to patients and staff alike. In her spare time she enjoys spending time with her family, playing tennis, and crafting."
        Title2_1="Nurse"
        Title2_2="Practitioner"
        hr2={<hr className="hr w-25" />}
        />
        <TwoImgsTwoTexts
                ProvidersCls1="section-img-1-providers"
                ProvidersCls2="section-img-2-providers"
            IDDoctor="morgan-johnson"
            Display="container-fluid"
            SubTitle1="Morgan Johnson"
            SubTitle2="Nicole Bell"
            Alt1="Morgan Johnson"
            Alt2="Christy"
            Img1={Img3}
            Img2={Img4}
            Title1_1="Medical"
            Title1_2="Assistant"
            hr1={<hr className="hr w-25" />}
            Para1=""
            Title2_1="Medical"
            Title2_2="Assistant"
            hr2={<hr className="hr w-25" />}
            Para2=""
        />

        <TwoImgsTwoTexts
                ProvidersCls1="section-img-1-providers"
                ProvidersCls2="section-img-2-providers"
                IDDoctor="christy"
            Display="container-fluid"
            SubTitle1="Christy Oborne"
            SubTitle2="Brandee Black"
            Alt1="Christy"
            Alt2="Brandy"
            Img1={Img5}
            Img2={Img6}
            Title1_1="Medical"
            Title1_2="Assistant"
            hr1={<hr className="hr w-25" />}
            Para1=""
            Title2_1="Front"
            Title2_2="Desk"
            hr2={<hr className="hr w-25" />}
            Para2=""
        />
        <div style={{marginTop: "1.5em"}}>
            <MiddleBannerMap/>
        </div>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default Providers;