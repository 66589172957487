import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import Banner from "../components/layout/Banner";
import ServicesPara from "../components/services/ServicesPara";
import OurServices from "../components/home/OurServices";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import OurInsurance from "../components/layout/OurInsurance";
import TakeActionToday from "../components/layout/TakeActionToday";
import Footer from "../components/layout/Footer";
import BgImage from "../assets/images/ourservices/main/Banner.png";
import { Helmet } from "react-helmet";

const Services=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Our Services In Huntsville| My Family MD </title>
        <meta name="description" content="We offer top healthcare services in Huntsville, including primary care, preventive care, and teleconsultations." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Our Services | My Family MD" />
        <meta property="og:description" content="We offer top healthcare services in Huntsville, including primary care, preventive care, and teleconsultations." />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="We offer top healthcare services in Huntsville, including primary care, preventive care, and teleconsultations." />
        <meta name="twitter:title" content="Our Services | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />
        </Helmet>
        <Header/>
        <Banner
        BgImage={BgImage}
        Title1="Our Services "
        Title2="Your Health,"
        br={<br className="d-none d-md-block"/>}
        Title2_2="Our Expertise:"
        Title3="Unparalleled Services Await" 
        />
        <ServicesPara
        Para1_1="Unlock a world of comprehensive healthcare services at My Family MD. Our primary care is the cornerstone of your well-being, where our dedicated physicians establish lasting connections and provide personalized care. We help you embrace the convenience of telemedicine so that you can access top-quality healthcare from anywhere. Staying ahead of illness with our proactive preventive care, including screenings and vaccinations, has never been easy. With My Family MD, you can expect precise, engaging, and compassionate care that puts your health at the forefront so that you can experience healthcare excellence like never before."
        />
        <OurServices/>
        <br/>
        <MiddleBannerMap/>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default Services;