import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";

const Error=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Header/>
        <section className="container-fluid py-5 my-5">
          <div className="row">
          <div className="col-12 text-center fw-600 text-capitalize text-pri-hsb" style={{fontSize:"70px"}}>
          404  Error
          </div>
          <div className="text-center">
          <Link to="/">
          <button className=" btn btn-dark rounded-0 font-25 fw-600 text-capitalize font-hover" style={{textDecoration: "none"}}>
            Go Back
          </button>
          </Link>        
          </div>
          </div>
        </section>
        <Footer/>
        </>
        
    )
};

export default Error;