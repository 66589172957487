import React, {useEffect, useState} from "react"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer";
import Banner from "../components/layout/Banner";
import BgImage from "../assets/images/blogs/banner.webp";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { ChevronDoubleRight } from "react-bootstrap-icons";

const Blogs = () => {
    const [blogs, setBlogs] = useState([])
    const [contentLoading, setContentLoading] = useState(true)
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/website`).then(res => {
            // console.log(res.data.blogs.length);
            if(res.data.blogs.length > 0){
                // console.log(res.data.blogs);
                setBlogs(res.data.blogs)
            }else{
                setBlogs([])
            }
        }).catch(err => {
            console.log(err)
        }).finally (() => {
            // console.log(blogs);
            setContentLoading(false)
        })
    },[])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    
    return (
        <>
        <Helmet>
        <title>Blogs - My Family MD | Your Health, Our Commitment: Together, We Flourish</title>
        <meta name="description" content="Reach out to our dedicated team for outstanding healthcare services in Huntsville. Contact us today to discuss your healthcare needs and experience personalized care at its best." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="blogs | My Family MD" />
        <meta property="og:description" content="Reach out to our dedicated team for outstanding healthcare services in Huntsville. Contact us today to discuss your healthcare needs and experience personalized care at its best." />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Reach out to our dedicated team for outstanding healthcare services in Huntsville. Contact us today to discuss your healthcare needs and experience personalized care at its best." />
        <meta name="twitter:title" content="blogs | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />
        </Helmet>
        <Header/>
        <Banner
        BgImage={BgImage}
        Title1="Blogs"
        Title2="Your Health, "
        br={<br className="d-block d-md-none d-xl-block"/>}
        Title2_2="Our Commitment"
        Title3="Together, We Flourish" 
        />
          <section id="website-responsive" className="container py-4 font-pri">
                <div className="row justify-content-center justify-content-md-start gy-5">
                {contentLoading ?
                    <div className='col-md-12 col-sm-12 text-center'>
                        <Spinner animation="border" variant="dark" />
                    </div>
                :
                <>
                {blogs.length > 0 ?
                    <>
                        {blogs.map((blog, index) => {
                            return (
                                <div className="col-ss-4 shadow px-0 mx-3" style={{borderRadius:"22px"}}>
                                    <div className="text-center image-box" 
                                    style={{borderTopRightRadius:"20px", borderTopLeftRadius:"15px"}}>
                                        <Link className="" to={`/blog/${blog?.slug}`}>
                                            <img className='w-100' src={blog?.featured_image} alt={blog?.title} />
                                        </Link>
                                    </div>
                                    <div className='p-3 py-4'>
                                        <small className='blog-date text-zk-primary col-sec'>Date: 
                                            {/* July 27, 2023 in moment */}
                                            &nbsp; {moment(blog.created_at).format('MMMM DD, YYYY')}
                                        </small>
                                        <div className="fs-ss-18 pt-2">
                                            <Link className="text-black text-decoration-none" to={`/blog/${blog?.slug}`}>
                                                <div className="fs-ss-20 lh-sm pb-2" style={{fontWeight:'600'}}>{blog?.title}</div>
                                            </Link>
                                        </div>
                                        <div className="fs-ss-14 fw-500 pt-2">
                                            {blog?.description}
                                        </div>
                                        <div className='fs-ss-14 pt-3'>
                                            <button className=' border-0 bg-white ps-0'>
                                                <Link className="text-decoration-none fw-600 col-pri text-pri-hsb" to={`/blog/${blog?.slug}`}>
                                                    Read More <ChevronDoubleRight className=""/>
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    :
                    <div className='col-md-12 col-sm-12 text-center'>
                        <div className='fs-zk-22 text-dark text-montserrat mb-2' style={{fontWeight:"600"}}>No Blogs Found</div>
                    </div>
                    }
                
                </>
                }
                </div>
            </section>
        <Footer/>
        </>
    )
}

export default Blogs