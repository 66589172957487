import React,{useEffect, useState} from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
// import { Link } from 'react-router-dom'

const AllBlogs = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [blog, setBlog] = useState([])
    const [contentLoading, setContentLoading] = useState(true)

    useEffect(() => {
        // get last part of url
        const slug = window.location.href.split('/').pop()
        
        // get blog by slug
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/slug/${slug}`).then(res => {
            if(res.data){
                setBlog(res.data)
            }else{
                setBlog([])
            }
        }).catch(err => {
            console.log(err)
        }
        ).finally (() => {
            setContentLoading(false)
        })

    }, [])

    return (
        <>
        <Helmet>
            <title>{blog.metaTitle}</title>
            <meta name="description" content={blog.meta_description} />
            <meta name="keywords" content={blog.meta_keywords} />
            <meta property="og:title" content={blog.metaTitle} />
            <meta property="og:description" content={blog.meta_description} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="Sameena Medical Clinic" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
        </Helmet>
            <Header />
            <div className='bg-page' id="website-responsive">
                
                {/* <BannerCategory /> */}
                <section className='pb-4'>
                    <div className='container bg-light'>
                        {/* <div className='row pt-md-4 mb-md-4 gx-md-5' id="">
                        {contentLoading ?
                            <div className='col-md-12 col-sm-12 text-center'>
                                <Spinner animation="border" variant="dark" />
                            </div>
                            :
                            
                            <div className="col-md-10 p-3 mx-auto">
                                <div className="text-center">
                                    <img className='w-25' src={blog.featured_image} alt="Blog Image" />
                                </div>
                                <div className="py-4">
                                    <h1 className='lh-zk text-zk-primary' style={{fontWeight:"600"}}>
                                        {blog.title}
                                        <hr className='border-zk-black w-100px py-0 my-1'/>
                                    </h1>
                                    <div className='mt-3'>

                                        <div dangerouslySetInnerHTML={{__html: `${blog.content}`}}></div>
                                    </div>
                                    
                                    <div className='text-zk-primary '>
                                        Uploaded Date: {moment(blog.created_at).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>
                        }
                        </div> */}
                        <div className='row pt-md-4 mb-md-4 gx-md-5' id="">
                        {contentLoading ?
                            <div className='col-md-12 col-sm-12 text-center'>
                                <Spinner animation="border" variant="dark" />
                            </div>
                            :
                            
                            <div className="col-md-10 col-lg-9 p-3 mx-auto">
                                <div className="col-md-10 col-lg-9">
                                    <div className="text-center shadow">
                                        <img className='w-100' src={blog?.featured_image} alt="Blog Image" />
                                    </div>
                                </div>
                                <div className="py-4">
                                    <h1 className='lh-zk text-zk-primary' style={{fontWeight:"600"}}>
                                        {blog?.title}
                                        <hr className='border-zk-black w-100px py-0 my-1'/>
                                    </h1>
                                    <div className='mt-3'>

                                        <div dangerouslySetInnerHTML={{__html: `${blog?.content}`}}></div>
                                    </div>
                                    
                                    <div className='text-zk-primary '>
                                        {/* Uploaded  */}
                                        Uploaded Date: {moment(blog?.created_at).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </section>

            </div>

        <Footer />

        </>
    )
}

export default AllBlogs