export const ConsultativeReferralsSchema = () => {

  let data = {
    "@context": "https://schema.org", 
    "@type": "Service", 
    "serviceType": "Consultative Referrals", 
    "provider": { 
      "@type": "Organization", 
      "name": "My Family MD", 
      "url": "https://myfamilymd.org/", 
      "telephone": "(256)-489-9741" 
    }, 
    "areaServed": { 
      "@type": "City", 
      "name": "Huntsville" 
    }, 
    "description": "Expert consultative referrals for condition-specific patients in need of specialized healthcare services. We collaborate with a network of specialists to ensure individuals receive individualized and comprehensive care.", 
    "url": "https://myfamilymd.org/consultative-referrals-huntsville", 
    "image": "https://myfamilymd.org/og.png"     
  } 

  return JSON.stringify(data);
};