import React from "react"
import FooterLogo from "../../assets/images/home/FooterLogo.png"
import Footerbg from "../../assets/images/home/Footerbg.png"
import {Link} from 'react-router-dom'
import { Envelope, EnvelopeFill, Facebook, GeoAltFill, Instagram, Phone, PhoneFill, TelephoneFill } from "react-bootstrap-icons"



const Footer = () =>{
    return (
        <>
<div className="container-fluid p-3 bg-footer">
  <div className="row align-items-start justify-content-evenly text-white padding-footer">
    <div className="col-xl-4 col-12 pt-xl-0 mt-xl-0 ps-xl-5 ms-xl-5 pt-4">
        <img className="d-block d-md-none pt-md-0 mt-md-0" src={FooterLogo} style={{width: "210px"}} alt="Footer Logo"/>
        <img className="d-none d-md-block pt-md-0 mt-md-0" src={FooterLogo} style={{width: "250px"}} alt="Footer Logo"/>
        <div className="d-none d-md-block pt-3 w-75">
        <p className="font-pri-hsb">
        Choose excellence in healthcare with My Family MD. Explore our range of services and experience the highest standards of quality care and exceptional service. Your health and well-being deserve nothing less. Take the first step towards a healthier future with My Family MD.
        </p>
        </div>
        <div className="d-block d-md-none pt-3 w-100">
        <p className="font-pri-hsb">
        Choose excellence in healthcare with My Family MD. Explore our range of services and experience the highest standards of quality care and exceptional service. Your health and well-being deserve nothing less. Take the first step towards a healthier future with My Family MD.
        </p>
        </div>
        <div className="mt-4">
        <a href="tel:+12564899741"  target="_blank" > 
            <button className="lh-30 px-5 border-0 text-white font-pri-hsb fw-700 bg-pri"> (256)-489-9741 </button>
            </a>
            </div>
        <div className="mt-4" >
            <a className="td-none text-white" style={{fontSize:"30px"}}  target="_blank" href="https://www.instagram.com/myfamilymd_/" >
        {/* <i className="bi bi-instagram pe-2" ></i>  */}
        <Instagram className="me-2"/>
        </a>
        <a className="td-none text-white" style={{fontSize:"30px"}}  target="_blank" href="https://www.facebook.com/profile.php?id=100063556061289">
            {/* <i className="bi bi-facebook"></i></a> */}
            <Facebook />
            </a>
            </div>
    </div>
    <div className="col-xl-3 col-12 py-5 py-xl-0">
        {/* Table */}
        <h2 className="font-pri-hsb font-25 ">
            Working Hours
        </h2>
        <div style={{borderTop: "3px solid #01524b", maxWidth:"78px"}}>&nbsp;&nbsp;&nbsp;</div>
		<table className="font-pri-hsb table table-font mt-3 table-bordered text-white w-100  ">
			<tbody>
				<tr>
					<td>Monday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
				<tr>
					<td>Tuesday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
				<tr>
					<td>Wednesday</td>
					<td>7:30AM - 11:30AM</td>
				</tr>
                <tr>
					<td>Thursday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
                <tr>
					<td>Friday</td>
					<td>7:30AM - 11:30AM</td>
				</tr>
                <tr>
					<td>Sat - Sun</td>
					<td>Closed</td>
				</tr>
                <tr>
					<td>Lunch Break</td>
					<td>12:00PM - 1:00PM</td>
				</tr>
			</tbody>
		</table>
        </div>
            {/* Table */}

{/* Quick Links */}
   
    <div className="col-xl-4 col-12 ps-xl-5 ms-xl-5">
    <h2 className="font-pri-hsb font-25 " >Quick Links
    </h2>
    <div style={{borderTop: "3px solid #01524b", maxWidth:"78px"}}>&nbsp;&nbsp;&nbsp;</div>
    <div className="font-pri-hsb">
        <div className="mb-md-2"><Link className="td-none text-white"  to="/about-us">About Us</Link> </div>
        <div className="mb-md-2"><Link className="td-none text-white"  to="/contact-us">Contact Us</Link></div>
        <div className="mb-md-2"><Link className="td-none text-white"  to="/forms">Patient's Forms</Link> </div>
    </div>
    <h2 className="font-pri-hsb font-25 mt-5">Get In Touch
    </h2>
    <div style={{borderTop: "3px solid #01524b", maxWidth:"78px"}}>&nbsp;&nbsp;&nbsp;</div>
    <div className="font-pri-hsb">
        <div className="mb-md-2"><a className="td-none text-white"  target="_blank" href="tel:+12564899741" >
            {/* <i className="bi bi-telephone-fill"></i> */}
            <TelephoneFill className="me-2"/>
            (256)-489-9741</a></div>
        <div className="mb-md-2"><a className="td-none text-white"  target="_blank" href="mailto:office@iqbalsaeed.com" >
            {/* <i className="bi bi-envelope-fill"></i> */}
            <EnvelopeFill className="me-2"/>
            office@iqbalsaeed.com </a> </div>
        <div className="mb-md-2 pe-xl-5"><a className="td-none text-white"  target="_blank" href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q">
            {/* <i className="bi bi-geo-alt-fill "></i> */}
            <GeoAltFill className="me-2"/>
            2227 Drake Ave. Suite 7A Huntsville, AL 35805 </a> </div>
        </div>
        </div>
  </div>
</div>
{/* Quick Links */}
<section className="container-fluid bg-pri text-center pt-1 pb-2">
    <span className=" text-white font-14 font-pri-hsb"> Copyright by IqbalSaeed 2023. Powered by <a href="https://simplesolutionz.org/" className=" fw-500 text-white td-none" target="_blank">SimpleSolutionz</a> </span> 
    </section>
        </>
    )
}

export default Footer;