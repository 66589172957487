import React from "react"
// import { Link } from "react-router-dom"
import Divider from "../../assets/images/home/Divider.png"


const BelowBanner = (props) =>{
    return (
    <>
    <section className="container-fluid mb-md-5 mb-4">
        <div className="text-md-center"> 
            <h3 className="text-md-center font-35 font-pri-hsb">
                {props.Title1} <span className="fw-800 text-pri-hsb"> {props.Title2}</span>
            </h3>
            <img className="w-25 d-none d-md-inline" src={Divider} alt="" />
            <p className="text-start font-17 px-md-5 mx-md-5 pt-3 font-sec2-hsb">
                {props.Para}
            </p>
        </div>
    </section>
    </>
    )
}

export default BelowBanner;