import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer";
import BelowBanner from "../components/layout/BelowBanner";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import OurInsurance from "../components/layout/OurInsurance"
import TakeActionToday from "../components/layout/TakeActionToday";
import OurValues from "../components/aboutus/OurValues";
import BookNow from "../components/aboutus/BookNow";
import Img1 from "../assets/images/aboutus/Img1.png"
import Img2 from "../assets/images/aboutus/Img2.png"
import Banner from "../components/layout/Banner";
import BgImage from '../assets/images/aboutus/Banner.png'
import { Helmet } from "react-helmet";

// import OGImage from ''

const AboutUs=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title> About Us - My Family MD | Empowering Health, Transforming Lives </title>
        <meta name="description" content="Dedicated to improving well-being through patient-centered care. Experience exceptional healthcare with our comprehensive services." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Us - My Family MD | Empowering Health, Transforming Lives" />
        <meta property="og:description" content="Dedicated to improving well-being through patient-centered care. Experience exceptional healthcare with our comprehensive services." />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Dedicated to improving well-being through patient-centered care. Experience exceptional healthcare with our comprehensive services." />
        <meta name="twitter:title" content="About Us - My Family MD | Empowering Health, Transforming Lives" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />
        </Helmet>
        <Header/>
        <Banner
        BgImage={BgImage}
        Title1="About Us"
        Title2="Empowering Health,"
        Title3="Transforming Lives" 
        />
        <BelowBanner
        Title1="About"
        Title2="Us"
        Para="Welcome to My Family MD, where exceptional healthcare is our passion. With a comprehensive range of services including primary care, telemedicine, preventive care, and consultative referrals, we provide a seamless healthcare experience for you and your loved ones. Our dedicated team of compassionate professionals is committed to delivering personalized care, focusing on prevention and accessibility. Experience the highest level of healthcare that exceeds your expectations at My Family MD. Trust us with your family's well-being." 
        />
        <OurValues/>
        <BookNow/>
        <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-md-6 section-img-1" style={{backgroundImage:`url(${Img1})`}}>
            <img className='img-fluid invisible' alt="comprehensive internal medicine in Huntsville" src={Img1} />    
            </div>
            <div className="col-12 align-self-center col-md-6 p-md-5 p-3"> 
            <div className="">
            <h2 className="font-35 font-pri-hsb">
            Our <span className="fw-800 text-pri-hsb"> Mission </span>
            </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            At My Family MD, our mission is to provide exceptional healthcare services with a patient-centered approach. We are dedicated to improving the overall well-being of individuals and families by delivering comprehensive, compassionate, and accessible care. We strive for continuity of care, collaborating with specialists and providing seamless transitions between services. At My Family MD, we are your trusted partner in health, dedicated to making a positive impact on the lives of our community.
            </p>
            </div>
            </div>
        </div>
        </section>
        <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f6f6f6"}}>
        <div className="d-block d-md-none col-12 col-md-6 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="family care clinic in huntsville" src={Img2} />    
            </div>
            <div className="align-self-center col-12 col-md-6 p-md-5 p-3" > 
            <div className="">
            <h3 className="font-35 font-pri-hsb">
            Our <span className="fw-800 text-pri-hsb"> Goal </span>
            </h3>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Our goal is to revolutionize healthcare by empowering individuals and families to take control of their well-being. We strive to create a healthcare experience that goes beyond the conventional, where patients are active participants in their journey towards optimal health. Through personalized care, comprehensive services, and cutting-edge technologies, we aim to break down barriers and make healthcare more accessible, convenient, and compassionate. Together, let's redefine what it means to truly thrive in body, mind, and spirit.
            </p>
            </div>
            </div>
            <div className="d-none d-md-block col-12 col-md-6 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="family care clinic in huntsville"src={Img2} />    
            </div>
            </div>
            </section>

        <div className="pt-4">
        </div>
        <MiddleBannerMap/>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default AboutUs;